import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Seo"
import EntryHeader from "../../components/SingleTemplateComponents/EntryHeader"
import ShareBar from "../../components/SingleTemplateComponents/ShareBar"
import ComponentParser from "../../utils/ComponentParser"
import AudioPlayer from "react-h5-audio-player"
import PlayIcon from "../../components/svgs/PlayIcon"
import PauseIcon from "../../components/svgs/PauseIcon"
import VideoEmbed from "../../components/SingleTemplateComponents/VideoEmbed"

const episode = ( { data: { episode } } ) => {

	const podcast = episode?.podcasts?.nodes[0]?.name ? " - " + episode?.podcasts?.nodes[0]?.name : '';

	return (
		<Layout>
			<Seo
				title={episode.title}
				canonical={episode.seo?.canonical}
				uri={episode.uri}
				socialImage={episode.featuredImage?.node}
				description={episode.seo?.metaDesc ? episode.seo.metaDesc : episode.excerpt.replace(/(<([^>]+)>)/gi, "") }
			/>
			
			{ episode?.podcastMeta?.podcastMp3?.localFile?.publicURL &&
				<AudioPlayer
					src={episode.podcastMeta.podcastMp3.localFile.publicURL}
					showSkipControls={false}
					showJumpControls={false}
					layout="horizontal"
					customIcons={ {play: <PlayIcon />, pause: <PauseIcon /> }}
				/>
			}

			{ ! episode?.podcastMeta?.podcastMp3?.localFile?.publicURL && episode?.podcastMeta?.podcastMp3?.mediaItemUrl &&
				<AudioPlayer
					src={episode.podcastMeta.podcastMp3.mediaItemUrl}
					showSkipControls={false}
					showJumpControls={false}
					layout="horizontal"
					customIcons={ {play: <PlayIcon />, pause: <PauseIcon /> }}
				/>
			}

			{ ! episode?.podcastMeta?.podcastMp3?.localFile?.publicURL && ! episode?.podcastMeta?.podcastMp3?.mediaItemUrl && episode?.resourceMeta?.videoEmbed &&
				<div>
					<VideoEmbed 
						video={episode.resourceMeta.videoEmbed}
					/>
				</div>
			}

			<EntryHeader
				title={episode.title}
				date={episode.date}
				format={ "Podcast" + podcast }
			/>

			<ShareBar 
				url={episode.uri}
			/>

			<div className="max-w-2xl mx-auto mt-4 lg:-mt-64 pb-16 px-4 md:px-0 min-h-[40vh]">
				{episode.blocks &&
				episode.blocks.map((block, i) => {
					return (
						<div key={i}>
							<ComponentParser
								block={block}
								page={episode}
							/>
						</div>
					)
				})}
			</div>
			
		</Layout>
	);
}

export const episodeQuery = graphql`
	query EpisodeById(
		$id: String!
	) {
		episode: wpPodcastEpisode( id: { eq: $id } ) {
			... PodcastContent
		}
	}
`
export default episode